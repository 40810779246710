import { assert } from "./assert";
export async function waitAnimationFrames(frames) {
    for (let i = 0; i < frames; ++i) {
        await waitAnimationFrame();
    }
}
export async function waitAnimationFrame() {
    return new Promise((resolve) => {
        requestAnimationFrame(() => {
            resolve();
        });
    });
}
/**
 * Returns the lower cased tagName of an HTMLElement
 * @param element The element to get the tag name of or a tagName itself.
 */
export function tagOf(element) {
    var _a;
    return (_a = element === null || element === void 0 ? void 0 : element.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase();
}
/**
 * Use for elements that are rendered inside the Shadow DOM.
 * For everything else use Element.closest<TYPE>(selector).
 */
export function findUpperElement(of, tagName) {
    const lowerCaseTagName = tagName.toLowerCase();
    return findUpperElementByPredicate(of, (upperElement) => lowerCaseTagName === tagOf(upperElement));
}
/**
 * Checkes whether an element is displayed in the viewport. It does not check whether the element's visibility is hidden.
 * @param element: Element to check for
 */
export function isVisible(element, checkVisibilityStyle = false) {
    if (element.checkVisibility) {
        return element.checkVisibility({
            visibilityProperty: checkVisibilityStyle,
        });
    }
    if (checkVisibilityStyle) {
        const style = getComputedStyle(element);
        if (!style.visibility || style.visibility === "hidden") {
            return false;
        }
    }
    if (element.offsetParent) {
        return true;
    }
    if (!element.isConnected) {
        return false;
    }
    // TODO: Remove return statement when dx-modal uses "dialog" element again.
    return true;
    // TODO: Re-Add code below when dx-modal uses "dialog" element again.
    /**
     * Chrome bug:
     * The offsetParent can also be null if the element is within a dialog element. So we have to check the styles and the open status of the dialog
     * https://github.com/whatwg/html/issues/5520
     */
    /*
    const dialog = findUpperElement<HTMLDialogElement>(element, "dialog");
    if (dialog?.open) {
     const style = getComputedStyle(element);
     return style.visibility !== "hidden" && style.display !== "none";
    }
  
    return false;
    */
}
/**
 * Use for elements that are rendered inside the Shadow DOM.
 * For everything else use Element.closest<TYPE>(selector).
 */
export function findUpperElementByPredicate(of, predicate, breakCondition) {
    let parent = parentElement(of);
    while (parent != null && !predicate(parent)) {
        if (breakCondition === null || breakCondition === void 0 ? void 0 : breakCondition(parent)) {
            return null;
        }
        parent = parentElement(parent);
    }
    return parent;
}
/**
 * Returns closest parent scroll element.
 */
export function findClosestScrollElement(element) {
    return (findUpperElementByPredicate(element, (elem) => {
        if (!(elem instanceof HTMLElement || elem instanceof SVGElement)) {
            return false;
        }
        const computedStyle = getComputedStyle(elem, null);
        const style = (prop) => computedStyle.getPropertyValue(prop);
        const overflowValues = style("overflow") + style("overflow-x") + style("overflow-y");
        const scrollRegex = /(auto|scroll)/;
        return scrollRegex.test(overflowValues);
    }) || document.documentElement);
}
export function isActiveElement(element) {
    if (document.activeElement === element) {
        return true;
    }
    const upperRoot = element.getRootNode();
    return (upperRoot === null || upperRoot === void 0 ? void 0 : upperRoot.activeElement) === element;
}
export function containsActiveElement(element) {
    return (findUpperElementByPredicate(document.activeElement, (parent) => parent === element) != null);
}
export function getInnerActiveElement(element) {
    if (!isActiveElement(element)) {
        return null;
    }
    function _internalGetInnerActiveElement(element) {
        var _a;
        if (element == null) {
            return null;
        }
        return (_internalGetInnerActiveElement((_a = element.shadowRoot) === null || _a === void 0 ? void 0 : _a.activeElement) || element);
    }
    return _internalGetInnerActiveElement(element);
}
/**
 * Use for elements that are rendered inside the Shadow DOM.
 * For everything else use Element.closest<TYPE>(selector).
 */
export function isInnerElement(element, of) {
    let parent = parentElement(element);
    while (parent != null && parent !== of) {
        parent = parentElement(parent);
    }
    return parent === of;
}
export function parentElement(el) {
    var _a, _b;
    return (_b = (_a = el === null || el === void 0 ? void 0 : el.assignedSlot) !== null && _a !== void 0 ? _a : el === null || el === void 0 ? void 0 : el.parentElement) !== null && _b !== void 0 ? _b : getHost(el);
}
/**
 * Returns the host element if the given element is within a shadow-DOM and undefined when it is rendered in the document's light-DOM.
 */
export function getHost(el) {
    var _a;
    return (_a = el === null || el === void 0 ? void 0 : el.getRootNode()) === null || _a === void 0 ? void 0 : _a.host;
}
/**
 * Checks if two arrays contain the same items with the same indices within the array.
 * @param first First array
 * @param second Second array
 */
export function sameArrayInOrder(first, second) {
    if ((first === null || first === void 0 ? void 0 : first.length) === (second === null || second === void 0 ? void 0 : second.length)) {
        return first === null || first === void 0 ? void 0 : first.every((data, index) => {
            return data === second[index];
        });
    }
    return false;
}
/**
 * Checks if two arrays contain the same items.
 * @param first First array
 * @param second Second array
 */
export function sameArrayIgnoreOrder(first, second) {
    if ((first === null || first === void 0 ? void 0 : first.length) === (second === null || second === void 0 ? void 0 : second.length)) {
        const refMap = new Map();
        // Fill map and track each ref/value
        first === null || first === void 0 ? void 0 : first.forEach((value) => {
            var _a;
            const newValue = ((_a = refMap.get(value)) !== null && _a !== void 0 ? _a : 0) + 1;
            refMap.set(value, newValue);
        });
        // Decrement refs/values and check if there is no over decrement (< 0)
        const firstContainsSecond = second === null || second === void 0 ? void 0 : second.every((value) => {
            var _a;
            const newValue = ((_a = refMap.get(value)) !== null && _a !== void 0 ? _a : 0) - 1;
            refMap.set(value, newValue);
            return newValue >= 0;
        });
        return firstContainsSecond && Array.from(refMap.values()).every((value) => value === 0);
    }
    return false;
}
export function toNumberArray(arrayStringOrArray) {
    const errorMessageWhileParsing = () => "Found error parsing input as array of numbers. Please use e.g '[1,2,3]' as format. Input was: " +
        arrayStringOrArray;
    let valuesArray = null;
    if (Array.isArray(arrayStringOrArray)) {
        valuesArray = arrayStringOrArray;
    }
    else {
        try {
            valuesArray = JSON.parse(arrayStringOrArray);
        }
        catch (error) {
            console.error(errorMessageWhileParsing());
        }
    }
    const isValidNumberArray = !!valuesArray && Array.isArray(valuesArray) && valuesArray.every((value) => !isNaN(+value));
    assert(isValidNumberArray, errorMessageWhileParsing);
    return isValidNumberArray ? valuesArray : null;
}
/**
 * Filters nodes by tag name
 */
export function filterElementsByTagPrefix(nodes, tagName) {
    return nodes.filter((node) => node.tagName.toLocaleLowerCase().startsWith(tagName));
}
/**
 * Returns all elements slotted within an element.
 *
 * @param element
 * @param scoped
 */
export function getSlottedContent(element, selector = ":scope > *") {
    return Array.from(element.querySelectorAll(selector))
        .map((slottedElement) => getContentFromSlot(slottedElement))
        .flat();
}
export function isNamedSlotDefinedAndHasContent(element, slotName) {
    return getNamedSlottedContent(element, slotName).length > 0;
}
export function getNamedSlottedContent(element, slot) {
    if (!(slot === null || slot === void 0 ? void 0 : slot.length)) {
        throw Error("Slot must not be empty or undefined. Use getDefaultSlottedContent instead");
    }
    const query = slot ? `:scope > *[slot="${slot}"]` : ":scope > *:not([slot])";
    return Array.from(element.querySelectorAll(query))
        .map((element) => getContentFromSlot(element))
        .flat();
}
/**
 * Returns all nodes within an element.
 * @param element The element containing the slotted elements
 * @param includeEmptyTextNodes Whether to include empty text-nodes or not
 */
export function getDefaultSlottedContent(element, includeEmptyTextNodes = false) {
    const isTextNode = (node) => node.nodeType === 3 /* NODE_TYPES.TEXT_NODE */;
    return Array.from(element.childNodes)
        .filter((node) => isTextNode(node) || node.slot === "")
        .filter((node // Filter empty text nodes
    ) => includeEmptyTextNodes ||
        node.nodeType !== 3 /* NODE_TYPES.TEXT_NODE */ ||
        node.textContent.trim().length > 0)
        .map((node) => {
        if (isTextNode(node)) {
            return node;
        }
        return getContentFromSlot(node);
    })
        .flat();
}
export function isSkeletonSlotted(element, slotName) {
    const elementsWithinSlot = !slotName
        ? getDefaultSlottedContent(element)
        : getNamedSlottedContent(element, slotName);
    return elementsWithinSlot.some((element) => tagOf(element) === "dx-skeleton");
}
/**
 * Returns all contents within a slot element recursively. If the provided element is not a slot element it returns
 * the element itself.
 * @param element The slot element
 */
export function getContentFromSlot(element) {
    var _a, _b;
    const assignedElements = (_b = (_a = element).assignedElements) === null || _b === void 0 ? void 0 : _b.call(_a);
    if (assignedElements != null) {
        return assignedElements
            .map((elem) => {
            return getContentFromSlot(elem);
        })
            .flat();
    }
    return element;
}
/**
 * Checks whether an element could has overflowing content.
 * @param element The element which should be checked. Normally this would be an element which has the corresponding styles applied to it (text-overflow, overflow, etc.)
 *
 * @return Whether the element is overflowing.
 */
export function isOverflown(element) {
    // 8 is half of the minimum line-height of 16px. This is used as a bias because the scrollHeight is not always completely equal to the offsetHeight.
    const pxBias = 8;
    return (element === null || element === void 0 ? void 0 : element.offsetHeight) + pxBias < (element === null || element === void 0 ? void 0 : element.scrollHeight);
}
/**
 * Generates initials for a person's full name.
 * @param fullname The name to generate the initials from. The fullname has to begin with the firstname and has to end with
 * the lastname with all names separated by a space.
 */
export function generateInitialsForName(fullname) {
    if (fullname == null)
        return "";
    const split = fullname.trim().split(" ");
    if (split.length >= 2) {
        return `${split[0][0]}${split[split.length - 1][0]}`.toUpperCase();
    }
    if (split.length === 1) {
        return `${split[0][0]}`.toUpperCase();
    }
    return "";
}
export function createNumberArray(from, to) {
    return Array.from(Array(to - from + 1)).map((_, index) => index + from);
}
/* Replace a comma with a period */
export function replaceCommaWithDot(value) {
    return value === null || value === void 0 ? void 0 : value.replace(",", ".");
}
/**
 * Thanks to the never type this function throws a compile error, when the program can reach the function call.
 * @param never
 * Use this in the default case of a switch that swtiches on a enum type, to make it typesafe-ish.
 * @example switch(weekday) {
 *  case Weekday.Sunday:
 * return "Free";
 * default:
 *  throwCompileErrorIfReachable(weekday);
 * }
 *
 */
export const throwCompileErrorIfReachable = (never) => {
    never;
    //thanks to the never type this function throws a compile error, when the program can reach the function call.
};
// Objects have to have a getter and setter for the function to work properly
export function hookToUpdateProperty(obj, prop, onSetProperty) {
    try {
        const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(obj), prop);
        if (descriptor != undefined) {
            // Throws an error when a property is redefined
            Object.defineProperty(obj, prop, {
                set: function (newValue) {
                    const oldValue = descriptor.get.apply(this);
                    const setter = descriptor.set.apply(this, [newValue]);
                    if (newValue !== oldValue) {
                        onSetProperty === null || onSetProperty === void 0 ? void 0 : onSetProperty(newValue);
                    }
                    return setter;
                },
                get: function () {
                    return descriptor.get.apply(this);
                },
            });
        }
    }
    catch (e) { }
}
export function toPixels(num) {
    if (num == null) {
        return null;
    }
    return `${num}px`;
}
export function isInDialog(element) {
    return (findUpperElementByPredicate(element, (element) => {
        const elementTag = tagOf(element);
        return ["dx-modal", "dx-alert", "dx-popover"].includes(elementTag);
    }) != null);
}
export const PREVENT_FOCUS_IF = (condition) => {
    /* Prevent focus within slot */
    return { inert: condition };
};
export function getLocale() {
    if (typeof Intl !== "undefined") {
        return Intl.NumberFormat().resolvedOptions().locale;
    }
    if (window.navigator.languages) {
        return window.navigator.languages[0];
    }
    else {
        return window.navigator.userLanguage || window.navigator.language;
    }
}
